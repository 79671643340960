@import (reference, optional) "constants";
// do not include constants and make it optional (for our own applications, they add constants in a different way...)
@import "./celum-native-scrollbar";

.operation_icon {
  background-color: @color-black;
  color: white;
  margin: 1rem auto 0.5rem auto;
  cursor: pointer;
}

.magic-button-space .magic-button_operation:hover .operation_icon {
  // todo remove the !important statement after annotation styles are clean (contains old background color black)
  background-color: @color-black !important;
  opacity: @reduced-opacity;
}

.operation_name {
  color: fade(@color-black, 60%);
  text-transform: uppercase;
  width: 10.4rem;
  height: 2.5rem;
  overflow: hidden;
  text-align: center;
  font: @font-caption;
  cursor: pointer;
}

.operation_icon-big {
  @width: 5.4rem;
  width: @width;
  height: @width;
  border-radius: @width;
  line-height: @width;
}

.operation_icon-small {
  @width: 4.6rem;
  width: @width;
  height: @width;
  border-radius: @width;
  line-height: 0;

  & celum-icon {
    width: 100%;
    height: 100%;
  }
}

/* todo fix general styles, define final place */
/* todo use font sizes in em (scalable)? -> 1 em = current font size. eg. use document font size 12pt (and no other definition) -> scale all other font size defs */

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  /* see https://css-tricks.com/css-font-size/ */
  font-size: 62.5%;
}

body {
  font: @font-body;
}

a {
  color: var(--colorPrimary);
}

.headline {
  font: @font-headline;
}

.title {
  font: @font-title;
}

.subheader {
  font: @font-subheader;
}

.body,
.text,
.content-row {
  /* it should not be necessary to put this to every single list item cell*/
  font: @font-body;
}

.caption {
  font: @font-caption;
}

.topAction,
.button {
  font: @font-button;
}

.text-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 2rem;
}

.mat-mdc-dialog-container {
  padding: 0;
  border-radius: 0;
}

.bordered {
  text-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.2);
}

.box-shadow {
  box-shadow: @box-shadow-1dp;
}

.avatar-list .avatar-wrapper {
  float: left;
  margin-right: 0.5rem;
}

.size-xl {
  textarea,
  label {
    font: @font-display;
  }
}

.size-l {
  textarea,
  label {
    font: @font-headline;
  }
}

.size-m {
  textarea,
  label {
    font: @font-headline;
  }
}

.size-s {
  textarea,
  label {
    font: @font-title;
  }
}

.size-xs {
  textarea,
  label {
    font: @font-body;
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"] {
  appearance: none;
}

// fix for cut-off placeholder text
label.mat-form-field-label {
  min-height: 1.7rem;
}

.external-link {
  text-decoration: none;
  color: @color-primary;

  &:hover {
    text-decoration: underline;
  }
}

.celum-uppercase {
  text-transform: uppercase;
}

