@import '../../constants';
:root {

  .celum-input-form-field:not(.celum-input-form-field--light-substrate) {
    .mat-mdc-input-element {
      color: $color-blue-gray-900;
    }

    .mat-mdc-input-element:disabled {
      color: $color-blue-gray-400;
    }
  }

  .celum-input-form-field {
    .mat-mdc-form-field-error {
      font-size: 1.1rem;
    }
  }
}
