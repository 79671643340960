.mdc-menu-surface.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-panel {
  padding: 0;
}

/*
* --------------------------------------------------------
* Default Celum color palette
* $link: https://app.zeplin.io/project/5be9276a92d01e7ea2130210/screen/5be927c8ffcc753e759710a4
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Typography standards
* $link: https://app.zeplin.io/project/5be9276a92d01e7ea2130210/screen/5be927c8f9d5b53eec2be840
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Screen breakpoints
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Element reusable colors like borders, dividers etc
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Shadows standard
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* ... Next are in progress ...
* --------------------------------------------------------
*/
/**
* usage: @media \$smartphone-screens
*/
:root .celum-input-form-field:not(.celum-input-form-field--light-substrate) .mat-mdc-input-element {
  color: #263238;
}
:root .celum-input-form-field:not(.celum-input-form-field--light-substrate) .mat-mdc-input-element:disabled {
  color: #78909c;
}
:root .celum-input-form-field .mat-mdc-form-field-error {
  font-size: 1.1rem;
}

/*
* --------------------------------------------------------
* Default Celum color palette
* $link: https://app.zeplin.io/project/5be9276a92d01e7ea2130210/screen/5be927c8ffcc753e759710a4
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Typography standards
* $link: https://app.zeplin.io/project/5be9276a92d01e7ea2130210/screen/5be927c8f9d5b53eec2be840
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Screen breakpoints
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Element reusable colors like borders, dividers etc
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Shadows standard
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* ... Next are in progress ...
* --------------------------------------------------------
*/
/**
* usage: @media \$smartphone-screens
*/
mat-slider.mdc-slider.mat-mdc-slider {
  --mdc-slider-active-track-color: #90a4ae;
  --mdc-slider-handle-color: #90a4ae;
  --mdc-slider-focus-handle-color: #78909c;
  --mdc-slider-hover-handle-color: #78909c;
}
mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__track {
  height: 0;
}
mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__track .mdc-slider__track--active, mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__track .mdc-slider__track--inactive {
  border-radius: 0;
  height: 0.2rem;
}
mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__track .mdc-slider__track--inactive {
  top: -0.1rem;
}
mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__track .mdc-slider__track--active {
  background-color: #eceff1;
}
mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb .mdc-slider__thumb-knob, mat-slider.mdc-slider.mat-mdc-slider .mat-mdc-slider-visual-thumb .mdc-slider__thumb-knob, mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
  width: 1.2rem;
  height: 1.2rem;
  border-width: 0.6rem;
  box-shadow: 0 2px 4px -1px rgba(38, 50, 56, 0.2), 0 1px 10px 0 rgba(38, 50, 56, 0.12), 0 4px 5px 0 rgba(38, 50, 56, 0.14);
}
mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb .mdc-slider__value-indicator-container, mat-slider.mdc-slider.mat-mdc-slider .mat-mdc-slider-visual-thumb .mdc-slider__value-indicator-container, mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__value-indicator-container {
  bottom: 3.6rem;
  background-color: unset;
  width: 5rem;
  border: 0.1rem;
}
mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb .mdc-slider__value-indicator-container .mdc-slider__value-indicator, mat-slider.mdc-slider.mat-mdc-slider .mat-mdc-slider-visual-thumb .mdc-slider__value-indicator-container .mdc-slider__value-indicator, mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__value-indicator-container .mdc-slider__value-indicator {
  opacity: 1;
  transition: unset;
  transform: unset;
  background-color: unset;
  padding: unset;
  height: unset;
  display: flex;
}
mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb .mdc-slider__value-indicator-container .mdc-slider__value-indicator::before, mat-slider.mdc-slider.mat-mdc-slider .mat-mdc-slider-visual-thumb .mdc-slider__value-indicator-container .mdc-slider__value-indicator::before, mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__value-indicator-container .mdc-slider__value-indicator::before {
  content: unset;
}
mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb .mdc-slider__value-indicator-container .mdc-slider__value-indicator-text, mat-slider.mdc-slider.mat-mdc-slider .mat-mdc-slider-visual-thumb .mdc-slider__value-indicator-container .mdc-slider__value-indicator-text, mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__value-indicator-container .mdc-slider__value-indicator-text {
  color: #263238;
  font: normal 400 1.3rem/1.38 "Open Sans", Arial, sans-serif;
  text-align: center;
  flex: 1;
}
mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb .mat-ripple, mat-slider.mdc-slider.mat-mdc-slider .mat-mdc-slider-visual-thumb .mat-ripple, mat-slider.mdc-slider.mat-mdc-slider .mdc-slider__thumb--focused .mat-ripple {
  display: none;
}
mat-slider.mdc-slider.mat-mdc-slider:hover {
  --mdc-slider-handle-color: #78909c;
}

/*
* --------------------------------------------------------
* Default Celum color palette
* $link: https://app.zeplin.io/project/5be9276a92d01e7ea2130210/screen/5be927c8ffcc753e759710a4
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Typography standards
* $link: https://app.zeplin.io/project/5be9276a92d01e7ea2130210/screen/5be927c8f9d5b53eec2be840
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Screen breakpoints
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Element reusable colors like borders, dividers etc
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Shadows standard
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* ... Next are in progress ...
* --------------------------------------------------------
*/
/**
* usage: @media \$smartphone-screens
*/
.celum-expansion-panel {
  background-color: unset;
}
.celum-expansion-panel .mat-expansion-panel-header {
  height: 4rem;
  margin: 0 0.5rem;
  padding: 0 0 0 1.6rem;
  background-color: unset;
  font: normal 400 1.3rem/1.38 "Open Sans", Arial, sans-serif;
  color: #263238;
  font-weight: 600;
  border-bottom: #cfd8dc;
  border-width: 1px;
  border-style: hidden hidden solid hidden;
  border-radius: 0.3rem 0.3rem 0 0;
}
.celum-expansion-panel .mat-expansion-panel-header:hover, .celum-expansion-panel .mat-expansion-panel-header.mat-expanded:hover {
  background-color: rgba(55, 71, 79, 0.08);
  transition: 100ms;
}
.celum-expansion-panel .mat-expansion-panel-header.mat-expanded {
  height: 4rem;
}
.celum-expansion-panel .mat-expansion-panel-body {
  margin-top: 0.7rem;
  padding: 0;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0;
}

.mdc-data-table__row:last-child mat-cell.mdc-data-table__cell {
  border: inherit;
}

/*
* --------------------------------------------------------
* Default Celum color palette
* $link: https://app.zeplin.io/project/5be9276a92d01e7ea2130210/screen/5be927c8ffcc753e759710a4
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Typography standards
* $link: https://app.zeplin.io/project/5be9276a92d01e7ea2130210/screen/5be927c8f9d5b53eec2be840
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Screen breakpoints
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Element reusable colors like borders, dividers etc
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* Shadows standard
* --------------------------------------------------------
*/
/*
* --------------------------------------------------------
* ... Next are in progress ...
* --------------------------------------------------------
*/
/**
* usage: @media \$smartphone-screens
*/
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background-color: rgba(55, 71, 79, 0.12);
}

.mat-mdc-option:hover:not(.mdc-list-item--selected) {
  background-color: rgba(55, 71, 79, 0.08);
}

.mat-mdc-option {
  min-height: unset !important;
  font-size: inherit;
  line-height: 3.2rem;
  height: 3.2rem;
}
.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  width: 100%;
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mdc-menu-surface.mat-mdc-select-panel {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.cdk-overlay-pane {
  min-width: fit-content;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-elevation: 0;
}

.cdk-overlay-pane.fullscreen {
  --mdc-dialog-container-shape: 0;
  max-width: unset !important;
}
.cdk-overlay-pane.fullscreen .mat-mdc-dialog-container {
  max-height: unset;
}

.mat-mdc-dialog-surface {
  --mdc-dialog-container-shape: 0rem;
  position: initial !important;
}