@import (reference, optional) "../../constants";

:root {
  .mat-mdc-tooltip {
    .mdc-tooltip__surface {
      background-color: @color-blue-gray-700;
    }

    background-color: @color-blue-gray-700;
    word-break: break-word; // do not ellipse tooltip
    font: @font-caption;
    line-height: 1.27;
    border-radius: 0.2rem;
  }

  .mat-tooltip--darker .mdc-tooltip__surface {
    background-color: @color-blue-gray-800;
  }

  .mat-tooltip--lighter .mdc-tooltip__surface {
    background-color: @color-blue-gray-600;
  }
}
